/* eslint-disable react/prop-types */
// src/templates/pages/AreteOne.js

import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import React from 'react'
import Layout from '../../components/Layout'

function AreteOne({ data }) {
  const pageData = data.wpPage.template.pageAreteOne
  const heroSection = pageData.hero
  const empowerOrganization = pageData.empowerOrganization
  const dataStatistics = pageData.dataStatistics
  const cyberIntelligence = pageData.areteOneCyberIntelligence

  const imageData = getImage(empowerOrganization.image.localFile) || null
  const description = empowerOrganization.description.replace(
    /<ul>/g,
    `<ul class="list-disc text-sm font-semi space-y-responsive-xl-12 p-responsive-xl-12 ml-responsive-xl-16" >`
  )

  return (
    <Layout>
      <Seo post={data.wpPage} />

      {/* Page hero */}
      <section
      className='min-h-[360px] bg-cover bg-center bg-no-repeat flex items-center'
        style={{
          backgroundImage: `url(${heroSection.backgroundImage.sourceUrl})`,
          objectFit: 'fill',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="container">
          <div className="py-responsive-xl-48 text-left text-white">
            <h1 className="text-2xl font-light">{heroSection.title}</h1>
            <p className="mt-responsive-xl-20 text-lg font-semi leading-7 md:max-w-[600px]">
              {heroSection.subtitle}
            </p>
            <p className="mt-responsive-xl-16 text-sm font-regular leading-5 md:max-w-[600px]">
              {heroSection.description}
            </p>
          </div>
        </div>
      </section>

      {/* empower Organization */}
      <section className="container !mt-responsive-xl-48">
        <div className="flex flex-col gap-responsive-xl-20 px-responsive-xl-16 lg:flex-row lg:items-center">
          <div className="flex-1">
            <h3 className="text-md font-bold text-boynton-400">
              {empowerOrganization.title}
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </div>
          <div className="flex-1">
            {/* {empowerOrganization.image.sourceUrl && ( */}
            <GatsbyImage image={imageData} alt={''} />
            {/* )} */}
          </div>
        </div>
      </section>

      {/* Full width container */}
      <section className="!mt-responsive-xl-32 bg-[#021d44] px-responsive-xl-60 py-responsive-xl-48 text-center md:px-responsive-xl-80">
        <div
          className="container [&>p]:text-[24px] md:[&>p]:text-[40px] md:[&>p]:font-semi [&>p]:text-white [&>p>strong]:text-[24px] md:[&>p>strong]:text-[40px] md:[&>p>strong]:font-bold [&>p>strong]:text-[#007bff] text-center"
          dangerouslySetInnerHTML={{
            __html: dataStatistics,
          }}
        />
      </section>

      {/* Full width container */}
      <section className="!mt-0 bg-white px-responsive-xl-60 py-responsive-xl-48 text-center md:px-responsive-xl-80">
        <div
          className="container [&>p]:text-[24px] md:[&>p]:text-[40px] [&>p]:font-semi [&>p]:text-boynton-400 text-center font-semi"
          dangerouslySetInnerHTML={{
            __html: cyberIntelligence,
          }}
        />
      </section>
    </Layout>
  )
}

export default AreteOne

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        ...SeoFragment
      }
      template {
        templateName
        ... on WpTemplate_AreteOne {
          templateName
          pageAreteOne {
            hero {
              title
              subtitle
              description
              backgroundImage {
                sourceUrl
              }
            }
            empowerOrganization {
              title
              description
              image {
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
            dataStatistics
            areteOneCyberIntelligence
          }
        }
      }
    }
  }
`
